import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import firebase from 'firebase/app';
import 'firebase/messaging';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private static instance: firebase.app.App;
  
  constructor() {
    if (!FirebaseService.instance) {
      FirebaseService.instance = firebase.initializeApp(environment.firebase);
    }
  }

  getMessaging() {
    return firebase.messaging();
  }
}