
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export const environment: {
  production: boolean;
  API_URL: string;
  APP_URL: string;
  firebase: FirebaseConfig;
} = {
  production: false,
  API_URL: 'https://iquine.newmode.com.br/admin/api/',
  APP_URL: 'https://iquine.newmode.com.br/',
  firebase: {
    apiKey: "AIzaSyAI-NBRXY5xL3ab7t55MouGI7_HFrQrwvc",
    authDomain: "push-iclub.firebaseapp.com",
    projectId: "push-iclub",
    storageBucket: "push-iclub.firebasestorage.app",
    messagingSenderId: "26327906070",
    appId: "1:26327906070:web:726c4da9a071b096e5c237"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
